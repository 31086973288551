import componentsHandlers from "../componentsHandlers.js";
$("#menuNavBtn").on("click",(e)=>{
  $("#menuNavBtn").find("div:nth-child(1)").toggleClass("display-none")
  $("#menuNavBtn").find("div:nth-child(2)").toggleClass("display-none")
  $(".nav-side__ul").toggleClass("show")
})
window.navigating = false;
async function handleLocation() {
  window.navigating = true
  const path = window.location.pathname;
  const route = path;
  window.actualRoute = path
  $("#main-page").addClass("navigating")
  const html = await fetch(route).then((data) => data.text());
  $("#main-page").html(html)
  $("#nav-main").find(".nav__li").each(function(){
    if($(this).find("a").attr("href") === window.location.pathname){
      $(this).addClass("active")
    }else{
      $(this).removeClass("active")
    }
  })
  $("#main-page").removeClass("navigating")
  window.navigating = false
};
async function route(event){
  window.afterPath = window.location.pathname;
  event = event || window.event ;
  event.preventDefault();
  let anchor =  event.target
  while(anchor.nodeName!=="A"){
    anchor = anchor.parentElement
  }
  window.history.pushState({}, "", anchor.href);
  await handleLocation() 
}

$(document).ready(function() {
  Object.assign(window,componentsHandlers)
  $("#nav-main").find(".nav__li").each(function(){
    if($(this).find("a").attr("href") === window.location.pathname){
      $(this).addClass("active")
    }else{
      $(this).removeClass("active")
    }
  })
  window.onpopstate = handleLocation;
  window.route = route;

  $("#modalMainClose").on("click",(e)=>{
    e.preventDefault()
    $("#modalMain").addClass("display-none")
  })
})